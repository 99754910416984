import React from 'react';
import { Form } from 'semantic-ui-react';

import selfieImage from '../../../../../assets/images/documents/Selfie.jpg';
import { FormFieldImageUploadWrapper } from '../../../../common';
import FormLayout from '../../RegisterAuditorFormLayout/RegisterAuditorFormLayout.component';
import styles from './RegisterAuditorFormStepSeven.module.scss';

const RegisterForm = () => (
  <FormLayout subtitle="3 of 3" title="Upload images">
    <span className={styles.titleQuestion}>
      {'A date stamped photo of yourself'}
    </span>
    <img alt="bsi-logo" className={styles.image} src={selfieImage} />
    <p className={styles.noteQuestionItalic}>
      {'The photo must be a head and shoulder shot in your normal clothes, straight to the camera, clear background, from a distance of no more than 1 meter and date-stamped.'}
    </p>

    <p className={styles.noteQuestion}>
      {'Please ensure this image is clear and appropriate, as shown in the example above.'}
    </p>

    <Form.Field width={16}>
      <FormFieldImageUploadWrapper
        required
        thumbnail
        accept={['image/jpg', 'image/png']}
        name="image"
      />
    </Form.Field>
    <span className={styles.noteQuestion}>
      {'Allowed file types: .jpg, .png'}
    </span>
  </FormLayout>
);

export default RegisterForm;
