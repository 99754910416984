const AccountConst = require('./account');
const DocumentsConst = require('./documents');
const AuditsConst = require('./audits');

const ROOT_ELEMENT_ID = 'root';

const DEBOUNCE_MS = 1000;

const USER_INACTIVITY_MINUTES = 30;

const Environment = {
  DEVELOPMENT: 'development',
  LOCAL: 'local',
  PRODUCTION: 'production',
  STAGING: 'staging',
  TEST: 'test',
};

const DefaultSurveyResultType = {
  PASS: 'pass',
  FAIL: 'fail',
  ABORT: 'abort',
};

const CognitoUserChallengeNames = {
  SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  NOMFA: 'NOMFA',
};

const MfaMethods = {
  TOTP: 'TOTP',
};

const JWTPayloadProperties = {
  GROUPS: 'cognito:groups',
};

const Cookie = {
  SESSION_TOKEN: 'session_token',
  USERNAME: 'username',
  ROLES: 'roles',
};

const Currency = {
  EUR: 'EUR',
  GBP: 'GBP',
};

const Country = {
  GBP: 'United Kingdom',
  IRL: 'Ireland',
};

const CustomEventType = {
  // QUESTION events
  QUESTION_ADD_NEW: 'question_add_new',
  QUESTION_ADD_PREDETERMINED: 'question_add_predetermined',
  QUESTION_DRAG: 'question_drag',
  QUESTION_MOVE: 'question_move',
  // MAP events
  MAP_POLYGON_COMPLETE: 'map_polygon_complete',
  MAP_POLYGON_REMOVE: 'map_polygon_remove',
  MAP_ROUTE_REMOVE: 'map_route_remove',
};

const Entity = {
  AUDITORS: 'auditors',
  ARCHIVES: 'archives',
  AUDITS: 'audits',
  CLIENTS: 'clients',
  LOGICS: 'logics',
  NEWSFEED: 'newsfeed',
  ORDERS: 'orders',
  QUESTIONS: 'questions',
  SITES: 'sites',
  SURVEYS: 'surveys',
  PATCHES: 'patches',
  TAXONOMY: 'taxonomy',
  USERS: 'users',
};

// CAUTION!: enum values will be used to set the html input type for some elements
const QuestionType = {
  TITLE: 'title',
  CHECKBOX: 'checkbox',
  DATE: 'date',
  RADIO: 'radio',
  DATETIME: 'datetime',
  SELECT: 'select',
  TIME: 'time',
  TEXT: 'text',
  INTERVAL: 'interval',
  NUMBER: 'number',
  CURRENCY: 'currency',
  TEXTAREA: 'textarea',
  IMAGE: 'image',
};

const ReportType = {
  FULL: 'full',
  MINI: 'mini',
};

const SurveyEntity = {
  QUESTIONS: 'questions',
  BRANCHING: 'branching',
  VALIDATION: 'validation',
  REPORTS: 'reports',
  LOGIC: 'logic',
  MATCHING: 'matching',
};

const UserRole = {
  AREA_MANAGER: 'area_manager',
  AUDITOR: 'auditor',
  CLIENT_SERVICES: 'client_services',
  CLIENT: 'client',
  DATA: 'data',
};

const ColumnsLinkFormat = {
  AUDIT_ID: '/audits/#id',
  AUDITOR_ID: '/auditors/#auditor_id/view',
  ORDER_ID: '/orders/#order_id',
  SITE_ID: '/sites/#site_id',
  CLIENT_ID: '/clients/#client_id',
  NONE: null,
};

const AuditStatus = {
  UNASSIGNED: 'unassigned',
  ASSIGNED: 'assigned',
  SUBMITTED: 'submitted',
  APPROVED: 'approved',
  APPROVING_QUERY: 'approving_query',
  CLIENT_QUERY: 'client_query',
  DELETED: 'deleted',
};

const AuditorCoordinates = {
  HOME: 'home',
  TERM: 'term',
  CURRENT: 'current',
};

const CognitoConst = {
  userStatus: {
    CONFIRMED: 'CONFIRMED',
    FORCE_CHANGE_PASSWORD: 'FORCE_CHANGE_PASSWORD',
  },
};

const DateTimeFormat = {
  DAY_MONTH_YEAR: 'DD/MM/YYYY',
  DAY_MONTH_YEAR_HOUR_MINUTES_24H: 'DD/MM/YYYY HH:mm',
  HOUR_MINUTES_24H: 'HH:mm',
  YEAR_MONTH: 'YY/MM',
};

const DateTimeDiffFormat = {
  YEAR_MONTH: 'YM',
};

const CoordinatesTypes = {
  CURRENT: 'current_coordinates',
  HOME: 'home_coordinates',
  SITE: 'site_coordinates',
  TERM: 'term_coordinates',
  AUDIT_START: 'audit_start_coordinates',
};

const EntityState = {
  TABLE: 'table',
  DETAIL: 'detail',
};

const TravelModeTypes = {
  BICYCLING: 'BICYCLING',
  DRIVING: 'DRIVING',
  WALKING: 'WALKING',
};

const SortRule = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
};

const PageLimit = {
  SMALL: 50,
  MEDIUM: 100,
  LARGE: 150,
  EXTRA_LARGE: 200,
};

const AuditingSteps = {
  WELCOME: 'welcome',
  PREVIEW: 'preview',
  LOCATION: 'location',
  QUESTIONNAIRE: 'questionnaire',
  REVIEW: 'review',
  EXTRA_DETAILS: 'extra details',
  ONLINE_SENDER: 'loading',
  OFFLINE_FINISH: 'finish',
  ERROR: 'error',
  SUCCESS: 'success',
  SUBMITTED: 'submitted',
};

const getEntityMetadataByValue = entityValue => {
  const entityKey = Object.keys(Entity).find(
    key => Entity[key] === entityValue,
  );

  const metadata = {
    AUDITORS: { singular: 'auditor', plural: 'auditors' },
    AUDITS: { singular: 'audit', plural: 'audits' },
    CLIENTS: { singular: 'client', plural: 'clients' },
    LOGICS: { singular: 'logic', plural: 'logics' },
    ORDERS: { singular: 'order', plural: 'orders' },
    QUESTIONS: { singular: 'question', plural: 'questions' },
    SITES: { singular: 'site', plural: 'sites' },
    SURVEYS: { singular: 'survey', plural: 'surveys' },
    TAXONOMY: { singular: 'taxonomy', plural: 'taxonomy' },
    USERS: { singular: 'user', plural: 'users' },
  };

  return metadata[entityKey] || {};
};

const getQuestionCategoryMetadata = category => {
  const metadata = {
    generic: { icon: 'icon-generic' },
    alcohol: { icon: 'icon-alcohol' },
    tobacco: { icon: 'icon-tobacco' },
    gambling: { icon: 'icon-gambling' },
    weapons: { icon: 'icon-weapons' },
    adult_sex: { icon: 'icon-porn' },
    anagesics: { icon: 'icon-pills' },
    night_clubs: { icon: 'icon-pub' },
    lottery: { icon: 'icon-lottery' },
    sport_research: { icon: 'icon-media_research' },
    fireworks: { icon: 'icon-fireworks' },
  };

  return metadata[category] || {};
};

const getQuestionTypeMetadata = questionType => {
  const metadata = {
    TITLE: { label: 'Title', icon: 'icon-title' },
    CHECKBOX: { label: 'Checkboxes', icon: 'icon-checkbox' },
    DATE: { label: 'Date', icon: 'icon-date' },
    RADIO: { label: 'Radios', icon: 'icon-radio' },
    DATETIME: { label: 'Date and Time', icon: 'icon-datetime' },
    SELECT: { label: 'Select List', icon: 'icon-select' },
    TIME: { label: 'Time', icon: 'icon-time' },
    TEXT: { label: 'Text', icon: 'icon-text' },
    INTERVAL: { label: 'Interval', icon: 'icon-interval' },
    NUMBER: { label: 'Number', icon: 'icon-text' },
    CURRENCY: { label: 'Currency', icon: 'icon-currency' },
    TEXTAREA: { label: 'Textarea', icon: 'icon-textarea' },
    IMAGE: { label: 'Image', icon: 'icon-image' },
  };

  return metadata[questionType] || {};
};

const getQuestionTypeMetadataByValue = value => {
  const key = Object.keys(QuestionType).find(
    questionTypeKey => QuestionType[questionTypeKey] === value,
  );
  return getQuestionTypeMetadata(key);
};

module.exports = {
  ROOT_ELEMENT_ID,
  DEBOUNCE_MS,
  USER_INACTIVITY_MINUTES,
  AccountConst,
  AuditingSteps,
  AuditsConst,
  AuditStatus,
  AuditorCoordinates,
  CognitoConst,
  CognitoUserChallengeNames,
  ColumnsLinkFormat,
  Cookie,
  Country,
  Currency,
  CustomEventType,
  DateTimeDiffFormat,
  DateTimeFormat,
  DefaultSurveyResultType,
  DocumentsConst,
  Entity,
  Environment,
  MfaMethods,
  PageLimit,
  QuestionType,
  ReportType,
  SortRule,
  SurveyEntity,
  UserRole,
  getEntityMetadataByValue,
  getQuestionCategoryMetadata,
  getQuestionTypeMetadata,
  getQuestionTypeMetadataByValue,
  CoordinatesTypes,
  EntityState,
  TravelModeTypes,
  JWTPayloadProperties,
};
