import { useFeatureFlag } from 'configcat-react';
import React, { useRef } from 'react';
import { useAlert } from 'react-alert';
import { useMutation, useQuery } from 'react-query';

import { entitiesAPI, errorAPI } from '../../../api';
import { Entity } from '../../../constants';
import { useUserRole } from '../../../hooks';
import { getAccountRoleProps, getAccountRolePropsV2 } from '../../../utils/getAccountRoleProps';
import { LoadingPlaceholder } from '../../common';
import { View } from '../../layout';
import AccountHeader from './AccountHeader/AccountHeader.component';

const Account = () => {
  const { value: auditorApplicationUpgrade } = useFeatureFlag('auditorApplicationUpgrade', false);
  const role = useUserRole();
  const alert = useAlert();
  const accountRoleProps = auditorApplicationUpgrade ? getAccountRolePropsV2(role) : getAccountRoleProps(role);
  const { data, isFetching, refetch } = useQuery(
    [],
    () => accountRoleProps.query(),
  );
  const submitFormRef = useRef(null);

  const { mutate: accountUpdate } = useMutation(entitiesAPI.update, {
    onSuccess: () => {
      alert.success('Account updated');
    },
    onError: error => {
      alert.error(`Error updating account: ${JSON.stringify(error)}`);
      errorAPI.sendError(error.message, error.stack);
    },
  });

  const save = payload => accountUpdate({ entity: Entity.USERS, payload });

  return (
    <View
      secondary
      body={isFetching ? LoadingPlaceholder : accountRoleProps.body}
      bodyProps={{ data, submitFormRef, onSubmit: save, refetch }}
      dataCy="account-view"
      header={AccountHeader}
      headerProps={{ onSave: () => submitFormRef?.current?.click() }}
    />
  );
};

export default Account;
