import { useFeatureFlag } from 'configcat-react';
import { Formik } from 'formik';
import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';

import { entitiesAPI } from '../../../../api';
import { DEBOUNCE_MS, Entity } from '../../../../constants';
import { enumOptionsSelector } from '../../../../state/constants/selectors';
import { FormFieldSelectWrapper, FormFieldWrapper } from '../../../common';
import {
  auditorFilterDefaultValues,
  auditorFilterValidationSchema,
  formatStatusSelector,
  isNumericString,
} from '../helpers';

const DATA_CY = 'auditors-table-filter';

const auditorStatusTypeOptionsSelector = state => enumOptionsSelector(state, 'auditor_status_type');
const ageRangeTypeOptionsSelector = state => enumOptionsSelector(state, 'age_range_type');

const AuditorsTableFilter = ({ values: initialValues, onChange }) => {
  const { value: auditorApplicationUpgrade } = useFeatureFlag('auditorApplicationUpgrade', false);
  const auditorStatusTypeOptions = useSelector(auditorStatusTypeOptionsSelector);
  const newAuditorStatusTypeOptions = formatStatusSelector(auditorStatusTypeOptions);

  const ageRangeTypeOptions = useSelector(ageRangeTypeOptionsSelector);
  const patches = useQuery(
    [Entity.PATCHES],
    () => entitiesAPI.fetchAll(Entity.PATCHES),
  );
  const patchesSiteOptions = patches?.data?.items?.map(patch => ({ text: patch.name, value: patch.id })) || [];

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...initialValues,
        home_patch_id: initialValues.home_patch_id ? [initialValues.home_patch_id].flat() : [],
        term_patch_id: initialValues.term_patch_id ? [initialValues.term_patch_id].flat() : [],
      }}
      validationSchema={auditorFilterValidationSchema}
      onSubmit={values => {
        const numberValues = { ...values };
        if (isNumericString(numberValues.internal_id)) {
          numberValues.internal_id = `AUDITOR${numberValues.internal_id}`;
        }
        onChange(_pickBy(numberValues));
      }}
    >
      {({ handleSubmit }) => {
        const debounceSubmit = _debounce(handleSubmit, DEBOUNCE_MS);

        const handleFieldChange = () => {
          debounceSubmit();
        };

        return (
          <Form inverted data-cy={DATA_CY}>
            <Form.Group widths={4}>
              <FormFieldWrapper
                label="Name"
                name="name"
                onChange={handleFieldChange}
              />
              <FormFieldSelectWrapper
                clearable
                label="Status"
                name="auditor_status"
                options={auditorApplicationUpgrade ? newAuditorStatusTypeOptions : auditorStatusTypeOptions.filter(option => option.value !== 'on_hold_no_work')}
                onChange={handleSubmit}
              />
              <FormFieldSelectWrapper
                clearable
                label="Auditor Age"
                name="age_range"
                options={ageRangeTypeOptions}
                onChange={handleSubmit}
              />
              <FormFieldWrapper
                label="Auditor ID"
                name="internal_id"
                onChange={handleFieldChange}
              />
            </Form.Group>
            <Form.Group widths={4}>
              <FormFieldWrapper
                label="Home post code"
                name="home_post_code"
                onChange={handleFieldChange}
              />
              <FormFieldWrapper
                label="Term post code"
                name="term_post_code"
                onChange={handleFieldChange}
              />
              <FormFieldSelectWrapper
                clearable
                multiple
                label="Home Patches"
                name="home_patch_id"
                options={patchesSiteOptions}
                onChange={handleSubmit}
              />
              <FormFieldSelectWrapper
                clearable
                multiple
                label="Term Patches"
                name="term_patch_id"
                options={patchesSiteOptions}
                onChange={handleSubmit}
              />
            </Form.Group>
          </Form>
        );
      }}
    </Formik>
  );
};

AuditorsTableFilter.defaultProps = {
  values: auditorFilterDefaultValues,
};

AuditorsTableFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    date_of_birth: PropTypes.string,
    home_post_code: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    term_address_post_code: PropTypes.string,
  }),
};

export default AuditorsTableFilter;
