import PropTypes from 'prop-types';

import {
  Currency,
  Entity,
  QuestionType,
} from '../constants';

// TODO: fix models

/**
 * UUID type
 */
const requiredUuidType = (props, propName, componentName) => {
  const value = props[propName];

  if (!/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/.test(value)) {
    return new Error(`Invalid prop ${propName} supplied to ${componentName}. Validation failed.`);
  }

  return null;
};

export const uuidType = (props, propName, componentName) => {
  if (props[propName] == null) return null;
  return requiredUuidType(props, propName, componentName);
};

uuidType.isRequired = requiredUuidType;

/**
 * DATE type
 */
const requiredDateTimeType = (props, propName, componentName) => {
  const value = props[propName];

  if (!/^(\d{4})[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])[T]([01][0-9]|[2][0-3])[:]([0-5][0-9])[:]([0-5][0-9])[.](\d{3})[Z]$/ // eslint-disable-line max-len
    .test(value)) {
    return new Error(`Invalid prop ${propName} (${value}) supplied to ${componentName}. Validation failed.`);
  }

  return null;
};

export const dateTimeType = (props, propName, componentName) => {
  if (props[propName] == null) return null;
  return requiredDateTimeType(props, propName, componentName);
};

dateTimeType.isRequired = requiredDateTimeType;

/**
 * MONETARY type
 *
 * Object of two elements:
 *   - value: number with the quantity.
 *   - currency: id of the currency.
 */
export const monetaryType = PropTypes.shape({
  currency: PropTypes.oneOf(Object.values(Currency)).isRequired,
  value: PropTypes.number.isRequired,
});

/**
 * ENTITY type
 */
export const entityType = PropTypes.oneOf(Object.values(Entity));

/**
 * CLIENT type
 */
const minimisedClient = {
  client_type: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  id: uuidType.isRequired,
  internal_id: PropTypes.string.isRequired,
  manager_email: PropTypes.string.isRequired,
  manager_id: uuidType.isRequired,
  manager_name: PropTypes.string.isRequired,
  modified_at: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export const minimisedClientType = PropTypes.shape(minimisedClient);

export const clientType = PropTypes.shape({
  ...minimisedClient,
  sage_reference: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['active', 'inactive']).isRequired,
  store_locator: PropTypes.string.isRequired,
  contract_expiry: PropTypes.string,
  footer: PropTypes.string,
  form_recognition_rules: PropTypes.object,
});

/**
 * SITE type
 */
export const siteType = PropTypes.shape({
  address_1: PropTypes.string.isRequired,
  address_2: PropTypes.string.isRequired,
  address_3: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  client_id: uuidType.isRequired,
  code: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  id: uuidType.isRequired,
  id_public: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  post_code: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['active', 'inactive']).isRequired,
  county: PropTypes.string,
  directions: PropTypes.string,
  organisation_level_code: PropTypes.arrayOf(PropTypes.object),
});

/**
 * USER type
 */
export const userType = PropTypes.shape({
  email: PropTypes.string.isRequired,
  first_name: PropTypes.string.isRequired,
  id: uuidType.isRequired,
  last_name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['active', 'inactive']).isRequired,
  allow_archive: PropTypes.boolean,
  image: PropTypes.string,
  middle_name: PropTypes.string,
  notify_user: PropTypes.boolean,
});

/**
 * LOGIC type
 */
export const logicRuleType = PropTypes.shape({
  logic: PropTypes.string.isRequired,
  result: PropTypes.string.isRequired,
});

export const logicType = PropTypes.shape({
  id: uuidType.isRequired,
  internal_id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.arrayOf(logicRuleType).isRequired,
  status: PropTypes.oneOf(['active', 'inactive']).isRequired,
  survey_result_type: PropTypes.string.isRequired,
});

/**
 * ORDER type
 */
export const orderType = PropTypes.shape({
  client_id: uuidType.isRequired,
  id: uuidType.isRequired,
  item_to_order: PropTypes.string.isRequired,
  schedule_type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  approving_notes: PropTypes.string,
  auditor_instructions: PropTypes.string,
  auditor_pay_per_audit: monetaryType,
  audits_in_system: PropTypes.number,
  briefing_documents: PropTypes.arrayOf(PropTypes.string),
  client_charge_per_audit: monetaryType,
  email_copy: PropTypes.array,
  email_subject: PropTypes.string,
  email_survey_result: PropTypes.string,
  end_date: dateTimeType,
  exclude_from_client_reporting: PropTypes.boolean,
  info: PropTypes.string,
  narv_visits_only: PropTypes.boolean,
  start_date: dateTimeType,
  survey_id: uuidType,
  visits_to_be_billed: PropTypes.number,
});

/**
 * SURVEY type
 */
export const surveyType = PropTypes.shape({
  id: uuidType.isRequired,
  title: PropTypes.string.isRequired,
});

/**
 * QUESTION type
 */
export const questionType = PropTypes.shape({
  config: PropTypes.object.isRequired,
  help: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(QuestionType)).isRequired,
  id: uuidType,
});

/**
 * BRANCHING RULE type
 */
export const branchingRuleType = PropTypes.shape({
  hidden_questions_ids: PropTypes.arrayOf(uuidType).isRequired,
  logic: PropTypes.string.isRequired,
});

/**
 * VALIDATION RULE type
 */
export const validationRuleType = PropTypes.shape({
  logic: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
});

/**
 * REPORTS type
 */
export const reportsType = PropTypes.shape({
  full: PropTypes.arrayOf(uuidType).isRequired,
  mini: PropTypes.arrayOf(uuidType).isRequired,
});

/**
 * REVISION type
 */
export const revisionType = PropTypes.shape({
  branching: PropTypes.arrayOf(PropTypes.object).isRequired,
  id: uuidType.isRequired,
  logic: PropTypes.arrayOf(PropTypes.object).isRequired,
  name: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(questionType).isRequired,
  reports: PropTypes.arrayOf(PropTypes.object).isRequired,
  status: PropTypes.oneOf(['active', 'inactive']).isRequired,
  survey_id: uuidType.isRequired,
  validation: PropTypes.arrayOf(PropTypes.object).isRequired,
});

/**
 * AUDITOR type
 */
export const auditorType = PropTypes.shape({
  first_name: PropTypes.string.isRequired,
  id: uuidType.isRequired,
  last_name: PropTypes.string.isRequired,
  middle_name: PropTypes.string,
});

/**
 * AUDIT type
 */
export const auditType = PropTypes.shape({
  auditor_id: uuidType.isRequired,
  client_id: uuidType.isRequired,
  id: uuidType.isRequired,
  order_id: PropTypes.string.isRequired,
  result: PropTypes.string.isRequired,
  schedule_type: PropTypes.string.isRequired,
  site_id: PropTypes.string.isRequired,
  visit_deadline: PropTypes.string.isRequired,
  info: PropTypes.string,
});

/**
 * TAXONOMY type
 */
export const taxonomyType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  terms: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      disabled: PropTypes.bool.isRequired,
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })),
  })).isRequired,
  vocabulary: PropTypes.string.isRequired,
});
