import * as Yup from 'yup';

export const auditFilterValidationSchema = Yup.object().shape({
  audit: Yup.string(),
  audit_date_from: Yup.date().nullable(),
  audit_date_to: Yup.date().nullable(),
  auditor: Yup.string(),
  auditor_internal_id: Yup.string(),
  client: Yup.string(),
  name: Yup.string(),
  number_of_audits: Yup.number().min(0),
  order: Yup.string(),
  order_deadline_from: Yup.date().nullable(),
  order_deadline_to: Yup.date().nullable(),
  order_schedule_type: Yup.array(Yup.string()),
  paid: Yup.string(),
  patch_name: Yup.string(),
  pdf_exported: Yup.string(),
  post_code: Yup.string(),
  site: Yup.string(),
  status: Yup.array(Yup.string()),
});

export const auditFilterDefaultValues = {
  audit_date_from: null,
  audit_date_to: null,
  auditor_internal_id: '',
  audit: '',
  auditor: '',
  client: '',
  name: '',
  number_of_audits: 0,
  order: '',
  order_deadline_from: null,
  order_deadline_to: null,
  paid: '',
  patch_name: '',
  pdf_exported: '',
  post_code: '',
  schedule_time: '',
  site: '',
  status: '',
};
