import { StatusCodes } from 'http-status-codes';

import { appConfig } from '../config';
import axios from './axiosInstance';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

export const create = async ({ entity, payload }) => axios.post(
    `${appConfig.backendUrl}/api/v1/${entity}`,
    payload,
    {
      headers: {
        Authorization: getAuthorization(),
        'Content-Type': 'multipart/form-data',
      },
    },
)
  .then(handlingResponse([StatusCodes.OK], `Error creating new ${entity}`))
  .catch(logError);

export const upload = async ({ entity, payload }) => axios.post(
    `${appConfig.backendUrl}/api/v1/${entity}/upload`,
    payload,
    {
      headers: {
        Authorization: getAuthorization(),
        'Content-Type': 'multipart/form-data',
      },
    },
)
  .then(handlingResponse([StatusCodes.OK], `Error uploading ${entity}`))
  .catch(logError);

export const update = async ({ entity, entityId, payload, version = 'v1' }) => axios.put(
    `${appConfig.backendUrl}/api/${version}/${entity}/${entityId}`,
    payload,
    {
      headers: {
        Authorization: getAuthorization(),
        'Content-Type': 'multipart/form-data',
      },
    },
)
  .then(handlingResponse([StatusCodes.OK], `Error updating ${entity} with id ${entityId}`))
  .catch(logError);

export const uploadTaxonomyDocument = async ({ entityId, payload }) => axios.put(
    `${appConfig.backendUrl}/api/v1/taxonomy/${entityId}/upload`,
    payload,
    {
      headers: {
        Authorization: getAuthorization(),
        'Content-Type': 'multipart/form-data',
      },
    },
)
  .then(handlingResponse([StatusCodes.OK], 'Error uploading document'))
  .catch(logError);
