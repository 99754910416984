import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Image } from 'semantic-ui-react';

import { AuxiliaryPanel, FullScreenImageModalV2 } from '../../../../common';
import styles from './AuditorDetailsImages.module.scss';

const DATA_CY = 'auditor-detail-view';

const images = [
  { label: 'Scanned Copy of Passport', src: 'passport' },
  { label: 'Scanned Copy of ID', src: 'identification' },
];

const AuditorDetailsImages = ({ data, refetch, initialCollapse }) => {
  const [modalImgSrc, setModalImgSrc] = useState(null);
  const [modalName, setModalName] = useState(null);

  const [collapsed, setCollapsed] = useState(initialCollapse);

  useEffect(() => {
    if (!data || !collapsed) {
      setCollapsed(!initialCollapse);
    }
  }, [data, collapsed, initialCollapse]);

  return (
    <>
      <AuxiliaryPanel
        condensed
        content={
          <div className={styles.row}>
            {images.filter(image => !!data[image.src])
              .map(image => (
                <div key={image.src}>
                  <div className={styles.container}>
                    <Image
                      rounded
                      className={styles.image}
                      inline={true}
                      size="medium"
                      src={data[image.src]}
                      onClick={() => {
                        setModalImgSrc(data[image.src]);
                        setModalName(image.src);
                      }
                      }
                    />
                  </div>
                </div>
              ))}
          </div>
        }
        DATA_CY={DATA_CY}
        header={(
          <span>
            {'User Images'}
          </span>
        )}
        initialCollapse={initialCollapse}
        showCollapse={true}
        onCollapse={newCollapsed => setCollapsed(newCollapsed)}
      />
      {data.visa && (
        <AuxiliaryPanel
          condensed
          content={
            <div className={styles.row}>
              <div key={data.visa}>
                <div className={styles.container}>
                  <Image
                    rounded
                    className={styles.image}
                    inline={true}
                    size="medium"
                    src={data.visa}
                    onClick={() => {
                      setModalImgSrc(data.visa);
                      setModalName('visa');
                    }
                    }
                  />
                </div>
              </div>
            </div>
          }
          DATA_CY={DATA_CY}
          header={(
            <span>
              {'Right To Work PDF'}
            </span>
          )}
          initialCollapse={!initialCollapse}
          showCollapse={true}
          onCollapse={newCollapsed => setCollapsed(newCollapsed)}
        />)}
      {
        modalImgSrc && (
          <FullScreenImageModalV2
            data={data}
            entityId={data.id}
            imgSrc={modalImgSrc}
            name={modalName}
            refetch={refetch}
            onClose={() => setModalImgSrc(null)}
          />
        )
      }
    </>
  );
};

AuditorDetailsImages.propTypes = {
  data: PropTypes.object.isRequired,
  initialCollapse: PropTypes.bool.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default AuditorDetailsImages;
