import PropTypes from 'prop-types';
import React from 'react';
import { Grid, GridColumn, GridRow, Tab } from 'semantic-ui-react';

import { EntityState } from '../../../../constants';
import MapAuxiliary from '../../Map/MapAuxiliary/MapAuxiliary.component';
import { bankInformation, filteredAuditorInformation } from '../helpers';
import AuditorDetailsImages from './AuditorDetailsImages/AuditorDetailsImages.component';
import AuditorDetailsProfile from './AuditorDetailsProfile/AuditorDetailsProfile.component';
import AuditorDetailsTab from './AuditorDetailsTab/AuditorDetailsTab.component';
import styles from './AuditorDetailsView.module.scss';

const DATA_CY = 'auditor-details-view';

const panes = (data, refetch) => [
  {
    menuItem: 'Auditor information',
    render: () => (
      <Tab.Pane className="basic" data-cy={`${DATA_CY}-tab-auditor-information`}>
        <Grid columns={2} doubling={true} stackable={true}>
          <GridRow>
            <GridColumn>
              <div className={styles.auditorInformation}>
                <AuditorDetailsTab
                  data={data}
                  dataCy="auditor-information"
                  display={filteredAuditorInformation(data)}
                />
              </div>
            </GridColumn>
            <GridColumn>
              <AuditorDetailsImages data={data} refetch={refetch} type={EntityState.DETAIL} />
              <MapAuxiliary data={data} type={EntityState.DETAIL} />
            </GridColumn>
          </GridRow>
        </Grid>
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Bank Information',
    render: () => (
      <Tab.Pane className="basic" data-cy={`${DATA_CY}-tab-bank-information`}>
        <AuditorDetailsTab
          data={data}
          dataCy="bank-information"
          display={bankInformation}
        />
      </Tab.Pane>
    ),
  },
];

const AuditorDetailsView = ({ data, refetch }) => (
  <div data-cy={DATA_CY}>
    <AuditorDetailsProfile data={data} dataCy={DATA_CY} refetch={refetch}/>
    <div className={styles.additionalInfo}>
      <Tab
        data-cy={`${DATA_CY}-tabs`}
        menu={{ secondary: true, pointing: true }}
        panes={panes(data, refetch)}
        refetch={refetch}
      />
    </div>
  </div>
);

AuditorDetailsView.propTypes = {
  data: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default AuditorDetailsView;
