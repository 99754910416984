import * as Yup from 'yup';

export const userValidationSchema = validationEmailDomainRegExp => Yup.object().shape({
  allow_archive: Yup.boolean(),
  client_id: Yup.string().when('role', {
    is: 'client',
    then: fieldSchema => fieldSchema.trim()
      .ensure()
      .min(1, 'Required'),
    otherwise: fieldSchema => fieldSchema.nullable(),
  }),
  email: Yup.string().when('role', {
    is: role => ['area_manager', 'data', 'client_services'].includes(role),
    then: Yup.string().email().matches(validationEmailDomainRegExp, 'Invalid email domain').required('Required'),
    otherwise: Yup.string().email('Invalid email').required('Required'),
  }),
  first_name: Yup.string()
    .trim()
    .ensure()
    .min(1, 'Required'),
  image: Yup.string()
    .nullable(),
  last_name: Yup.string()
    .trim()
    .ensure()
    .min(1, 'Required'),
  middle_name: Yup.string(),
  notify_user: Yup.boolean(),
  role: Yup.string()
    .trim()
    .ensure()
    .min(1, 'Required'),
  status: Yup.string()
    .trim()
    .ensure()
    .min(1, 'Required'),
});

export const userFilterDefaultValues = {
  role: null,
  status: null,
};

export const termsAndConditionsDefaultValues = {
  description: '',
};

export const termsAndConditionsValidationSchema = Yup.object().shape({
  description: Yup.string().ensure().min(1, 'Required'),
});

export const newsfeedDefaultValues = {
  content: '',
};

export const newsfeedValidationSchema = Yup.object().shape({
  content: Yup.string().ensure().min(1, 'Required'),
});

export const privacyNoticeDefaultValues = {
  created_at: '',
  location: '',
};

export const privacyNoticeValidationSchema = Yup.object().shape({
  created_at: Yup.string(),
  location: Yup.string(),
});

const statusEmailDictionary = {
  account_on_hold: 'Account On Hold - Action Required',
};

export const formatStatusEmailData = emailData => emailData.map(item => ({
  ...item,
  tab_text: statusEmailDictionary[item.name] || item.tab_text,
}));
