/* eslint-disable no-console */
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, GridColumn, Image, Label } from 'semantic-ui-react';

import { multipartAPI } from '../../../../../api';
import { Entity } from '../../../../../constants';
import { useUserRole } from '../../../../../hooks';
import { fetchUserInfo } from '../../../../../state/constants/actionCreators';
import { enumOptionsSelector } from '../../../../../state/constants/selectors';
import timeDiffToString from '../../../../../utils/timeDiffToString';
import { FormFieldWrapper, FullScreenImageModalV2 } from '../../../../common';
import { getPhoneNumberWithDialCode } from '../../helpers';
import styles from './AuditorDetailsProfile.module.scss';

const transportAvailableTypeSelector = state => enumOptionsSelector(state, 'transport_available_type');

const AuditorDetailsProfile = ({ data, refetch, dataCy }) => {
  const [modalImgSrc, setModalImgSrc] = useState(null);
  const alert = useAlert();
  const dispatch = useDispatch();
  const role = useUserRole();
  const transportAvailableOption = useSelector(transportAvailableTypeSelector);

  const { mutate: auditorMutate } = useMutation(
    multipartAPI.update,
    {
      onSuccess: () => {
        alert.success('Notes updated');
        dispatch(fetchUserInfo({ role }));
        refetch();
      },
      onError: error => {
        alert.error(`Error updating notes: ${error.message}`);
      },
    },
  );

  const onNotesChange = async values => {
    const formData = new FormData();
    const { identification, image, passport, visa, ...filteredValues } = values;

    Object.entries(filteredValues).forEach(([key, value]) => formData.append(key, value));

    return auditorMutate({
      entity: Entity.AUDITORS,
      entityId: data.id,
      payload: formData,
    });
  };

  const formattedTransportAvailable = transportAvailableOption
    ? transportAvailableOption.find(option => option.value === data.transport_available)?.text
    : 'N/A';

  return (
    <>
      <div className={styles.container}>
        <div className={styles.basicInfo}>
          <Image
            rounded
            className={styles.image}
            size="small"
            src={data.image}
            onClick={() => setModalImgSrc(data.image)}
          />
          <div className={styles.auditor}>
            <div className={styles.preferedName} data-cy={`${dataCy}-name`}>
              {data.preferred_first_name ? [data.preferred_first_name, data.preferred_last_name].join(' ')
                : [data.first_name, data.last_name].join(' ')}
            </div>
            <div className={styles.account}>
              <span className={styles.accountTitle} data-cy={`${dataCy}-passport`}>
                {'Passport Name: '}
              </span>
              <span data-cy={`${dataCy}-since`}>
                {[data.first_name, data.middle_name, data.last_name].join(' ')}
              </span>
            </div>
            <div className={styles.account}>
              <span className={styles.accountTitle} data-cy={`${dataCy}-pronouns`}>
                {'Pronouns: '}
              </span>
              <span data-cy={`${dataCy}-since`}>
                {(data.preferred_pronouns === 'Other' && data.other_pronoun) || data.preferred_pronouns}
              </span>
            </div>
            <div className={styles.account}>
              <span className={styles.accountTitle} data-cy={`${dataCy}-age`}>
                {'Age: '}
              </span>
              <span data-cy={`${dataCy}-age`}>
                {`${timeDiffToString(data.date_of_birth, 'YM')}`}
              </span>
            </div>
            <div className={styles.account}>
              <span className={styles.accountTitle} data-cy={`${dataCy}-transport`}>
                {'Transport available to you: '}
              </span>
              <span data-cy={`${dataCy}-transport`}>
                {formattedTransportAvailable}
              </span>
            </div>
            <div className={styles.account}>
              <span className={styles.accountTitle} data-cy={`${dataCy}-since`}>
                {'Member for: '}
              </span>
              <span data-cy={`${dataCy}-since`}>
                {`${timeDiffToString(data.created_at, 'YMD') || '0 days'}`}
              </span>
            </div>
          </div>
          <Grid columns={4} doubling={true} stackable={true}>
            <GridColumn>
              <div className={styles.statistic}>
                <span className={styles.accountTitle} data-cy={`${dataCy}-logged`}>
                  {'Last logged in  '}
                </span>
                <span>
                  {timeDiffToString(data.last_access, 'YMD') || 'N/A'}
                </span>
              </div>
              <div className={styles.statistic}>
                {['unapproved', 'on_hold', 'on_hold_no_work'].includes(data.auditor_status) && (
                  <>
                    <span className={styles.accountTitle} data-cy={`${dataCy}-application-date`}>
                      {'Application date'}
                    </span>
                    <span>
                      {data.application_date
                        ? new Date(data.application_date).toLocaleDateString()
                        : 'N/A'}
                    </span>
                  </>
                )}
              </div>
              <div className={styles.statistic}>
                <span className={styles.accountTitle} data-cy={`${dataCy}-inactivation`}>
                  {'Inactivation date  '}
                </span>
                <span>
                  {data.inactivation_date
                    ? new Date(data.inactivation_date).toLocaleDateString() : 'N/A'}
                </span>
              </div>
            </GridColumn>
            <GridColumn>
              <div className={styles.statistic}>
                <span className={styles.accountTitle} data-cy={`${dataCy}-narv-count`}>
                  {'NARV audit count  '}
                </span>
                <span>
                  {data.auditor_narv_visit_count || 'N/A'}
                </span>
              </div>
              <div className={styles.statistic}>
                <span className={styles.accountTitle} data-cy={`${dataCy}-first-narv-date`}>
                  {'First NARV audit date  '}
                </span>
                <span>
                  {data.auditor_first_narv_visit_date
                    ? new Date(data.auditor_first_narv_visit_date).toLocaleDateString()
                    : 'N/A'}
                </span>
              </div>
              <div className={styles.statistic}>
                <span className={styles.accountTitle} data-cy={`${dataCy}-last-narv-date`}>
                  {'Last NARV audit date  '}
                </span>
                <span>
                  { data.auditor_last_narv_visit_date
                    ? new Date(data.auditor_last_narv_visit_date).toLocaleDateString() : 'N/A'}
                </span>
              </div>
            </GridColumn>
            <GridColumn>
              <div className={styles.statistic}>
                <span className={styles.accountTitle} data-cy={`${dataCy}-count`}>
                  {'SL audit count  '}
                </span>
                <span>
                  {data.auditor_sl_visit_count || 'N/A'}
                </span>
              </div>

              <div className={styles.statistic}>
                <span className={styles.accountTitle} data-cy={`${dataCy}-first-audit`}>
                  {'First SL audit date'}
                </span>
                <span >
                  {data.auditor_first_sl_visit_date
                    ? new Date(data.auditor_first_sl_visit_date).toLocaleDateString() : 'N/A'}
                </span>
              </div>
              <div className={styles.statistic}>
                <span className={styles.accountTitle} data-cy={`${dataCy}-last-audit`}>
                  {'Last SL audit date'}
                </span>
                <span>
                  {data.auditor_last_sl_visit_date
                    ? new Date(data.auditor_last_sl_visit_date).toLocaleDateString() : 'N/A'}
                </span>
              </div>
            </GridColumn>
            <GridColumn>
              <div className={styles.statistic}>
                <span className={styles.accountTitle} data-cy={`${dataCy}-pass-rate`}>
                  {'Auditor pass rate  '}
                </span>
                <span>
                  {data.auditor_pass_rate ? `${data.auditor_pass_rate}%` : 'N/A'}
                </span>
              </div>
            </GridColumn>
          </Grid>
          <Grid columns={3}>
            <Formik
              initialValues={data}
              onSubmit={values => onNotesChange(values)}
            >
              {({ handleSubmit }) => (
                <>
                  <div className={styles.notes} >
                    <span className={styles.accountTitle} data-cy={`${dataCy}-notes`}>
                      {'Notes'}
                    </span>
                    <FormFieldWrapper
                      className={styles.notesfield}
                      data-cy={`${dataCy}-notes`}
                      name="notes"
                      type="textarea"
                    />
                    <Button
                      primary
                      data-cy={`${dataCy}-notes-save`}
                      size="mini"
                      type="submit"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                    Save note
                    </Button>
                  </div>
                </>
              )}
            </Formik>
          </Grid>
        </div>
        <div className={styles.contact}>
          <Label content={getPhoneNumberWithDialCode(data.mobile_prefix, data.mobile_number)} data-cy={`${dataCy}-mobile-number`} icon="phone" />
          <Label as="a" content={data.email} data-cy={`${dataCy}-email`} href={`mailto:${data.email}`} icon="mail" />
        </div>
      </div>
      {
        modalImgSrc && (
          <FullScreenImageModalV2
            data={data}
            entityId={data.id}
            imgSrc={modalImgSrc}
            name={'image'}
            refetch={refetch}
            onClose={() => setModalImgSrc(null)}
          />
        )
      }
    </>

  );
};

AuditorDetailsProfile.propTypes = {
  data: PropTypes.object.isRequired,
  dataCy: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default AuditorDetailsProfile;
