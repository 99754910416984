import { countries } from 'country-codes-flags-phone-codes';
import { subYears } from 'date-fns';
import * as Yup from 'yup';

import { shareCodeValidationWithoutSpaces, trimValidation } from '../../../../utils/validationHelpers';

const sortedCountries = countries
  .filter(country => country.name !== 'United Kingdom' && country.name !== 'Ireland')
  .sort((a, b) => a.name.localeCompare(b.name));

export const countryPrefixes = [
  countries.find(country => country.name === 'United Kingdom'),
  countries.find(country => country.name === 'Ireland'),
  ...sortedCountries,
];

export const hasVisaExpirationDate = [
  { key: 'yes', value: true, text: 'Yes' },
  { key: 'no', value: false, text: 'No' },
];

export const registerValidationSchemaWithoutAuditorApplicationUpgrade = [
  Yup.object().shape({
    accept_terms: Yup.boolean()
      .oneOf([true], 'You have to accept the Terms and Conditions of Use')
      .required('You have to accept the Terms and Conditions od Use'),
    email: Yup.string()
      .email('Invalid email')
      .required('Please enter your email'),
  }),
  Yup.object({
    date_of_birth: Yup.date()
      .typeError('Invalid date')
      .max(subYears(new Date(), 13), 'Unfortunately you must be over the age of 13 to apply to work with us. Please ensure you have entered your correct date of birth.')
      .min(subYears(new Date(), 150), 'That date of birth would make you the oldest person to have ever existed. Please ensure you have entered your correct date of birth.')
      .required('Plese enter your date of birth'),
    first_name: Yup.string()
      .test('trim', 'Please remove spaces before/after the first name', trimValidation)
      .required('Please enter your first name'),
    last_name: Yup.string()
      .test('trim', 'Please remove spaces before/after the last name', trimValidation)
      .required('Please enter your last name'),
    middle_name: Yup.string()
      .test('trim', 'Please remove spaces before/after the middle name', trimValidation),
  }),
  Yup.object({
    nationality: Yup.string().trim().ensure().min(1, 'Required'),
    country: Yup.string().required('Required'),
    ethnicity: Yup.string(),
    occupation: Yup.string().trim().ensure().min(1, 'Required'),
  }),
  Yup.object({
    gender: Yup.string().trim().ensure().min(1, 'Required'),
    honorific: Yup.string().trim().ensure().min(1, 'Required'),
    marital_status: Yup.string().trim().ensure().min(1, 'Required'),
  }),
  Yup.object({}),
  Yup.object().shape({
    passport_file: Yup.mixed().test('file', 'Please upload your passport', value => value?.name?.length > 0),
  }),
  Yup.object({
    identification_file: Yup.mixed().test('file', 'Please upload your current ID', value => value?.name?.length > 0),
    image_file: Yup.mixed().test('file', 'Please upload your photo', value => value?.name?.length > 0),
  }),
  Yup.object({
    visa_file: Yup.mixed(),
    visa_expiration: Yup.date().typeError('Invalid date').min(new Date(), 'Visa cannot be expired.'),
  }),
  Yup.object({
    home_address_1: Yup.string().required('Please enter your address'),
    home_address_2: Yup.string(),
    home_city: Yup.string().required('Please enter your city'),
    home_county: Yup.string().required('Please enter your county'),
    home_post_code: Yup.string()
      .when('country', {
        is: 'United Kingdom',
        then: Yup.string().trim().ensure().min(1, 'Required'),
      }),
  }),
  Yup.object({
    term_address_1: Yup.string().required('Please enter your secondary address.  If you do not have a secondary address, please re-enter your primary address'),
    term_address_2: Yup.string(),
    term_city: Yup.string().required('Please enter your city'),
    term_county: Yup.string().required('Please enter your county'),
    term_post_code: Yup.string()
      .when('country', {
        is: 'United Kingdom',
        then: Yup.string().trim().ensure().min(1, 'Required'),
      }),
  }),
  Yup.object({
    access_to_car: Yup.string().trim().ensure().min(1, 'Required'),
    email_communication: Yup.boolean(),
    home_number: Yup.string(),
    mobile_number: Yup.string()
      .required('Please enter your mobile number'),
    servelegal_community: Yup.boolean(),
  }),
  Yup.object({
    hear_about_us: Yup.string(),
    notes: Yup.string(),
    recommended_by: Yup.string(),
  }),
  Yup.object({
    about_yourself: Yup.string()
      .min(30, 'Please write a little bit more in order to progress your application')
      .required('Please enter a few words about yourself'),
    travel_outside: Yup.string(),
    why_servelegal: Yup.string()
      .min(30, 'Please write a little bit more in order to progress your application')
      .required('Please enter why you want to work for Serve Legal'),
  }),
  Yup.object().shape({
    communications_consent: Yup.boolean(),
    facial_images_consent: Yup.boolean(),
    privacy_notice: Yup.boolean()
      .oneOf([true], 'You have to accept the Privacy Notice')
      .required('You have to accept the Privacy Notice'),
    special_category_consent: Yup.boolean(),
  }),
];

export const registerValidationSchema = [
  Yup.object().shape({
    accept_terms: Yup.boolean()
      .oneOf([true], 'You have to accept the Terms and Conditions of Use')
      .required('You have to accept the Terms and Conditions od Use'),
    email: Yup.string()
      .email('Invalid email')
      .required('Please enter your email'),
  }),
  Yup.object({
    date_of_birth: Yup.date()
      .typeError('Invalid date')
      .max(subYears(new Date(), 13), 'Unfortunately you must be over the age of 13 to apply to work with us. Please ensure you have entered your correct date of birth.')
      .min(subYears(new Date(), 150), 'That date of birth would make you the oldest person to have ever existed. Please ensure you have entered your correct date of birth.')
      .required('Please enter your date of birth (DD/MM/YYYY)'),
    first_name: Yup.string()
      .test('trim', 'Please remove spaces before/after the first name', trimValidation)
      .required('Please enter your first name'),
    last_name: Yup.string()
      .test('trim', 'Please remove spaces before/after the last name', trimValidation)
      .required('Please enter your last name'),
    middle_name: Yup.string()
      .test('trim', 'Please remove spaces before/after the middle name', trimValidation),
    preferred_pronouns: Yup.string().trim().ensure().min(1, 'Required'),
    other_pronoun: Yup.string()
      .when('preferred_pronouns', {
        is: 'Other',
        then: Yup.string()
          .max(20, 'Please enter a maximum of 20 characters')
          .required('Please select your preferred pronouns before continuining'),
      }),
    preferred_first_name: Yup.string()
      .test('trim', 'Please remove spaces before/after the preferred first name', trimValidation)
      .required('Please enter your preferred first name'),
    preferred_last_name: Yup.string()
      .test('trim', 'Please remove spaces before/after the preferred last name', trimValidation)
      .required('Please enter your preferred last name'),
  }),
  Yup.object({
    nationality: Yup.string().trim().ensure().min(1, 'Required'),
    country: Yup.string().required('Required'),
    ethnicity: Yup.string(),
    occupation: Yup.string().trim().ensure().min(1, 'Required'),
  }),
  Yup.object({
    gender: Yup.string().trim().ensure().min(1, 'Required'),
    honorific: Yup.string().trim().ensure().min(1, 'Required'),
    marital_status: Yup.string().trim().ensure().min(1, 'Required'),
  }),
  Yup.object({}),
  Yup.object().shape({
    passport_file: Yup.mixed().test('file', 'Please upload your passport', value => value?.name?.length > 0),
  }),
  Yup.object({
    identification_file: Yup.mixed().test('file', 'Please upload your current ID', value => value?.name?.length > 0),
  }),
  Yup.object({
    image_file: Yup.mixed().test('file', 'Please upload your photo', value => value?.name?.length > 0),
  }),
  Yup.object({
    visa_file: Yup.mixed()
      .when('evidence_not_required', {
        is: false,
        then: Yup.mixed()
          .required('Please input your right to work details. If you do not need to provide further evidence, please select the checkbox above.')
          .test(
            'file',
            'Please upload a valid file.',
            value => value?.name?.length > 0,
          ),
        otherwise: Yup.mixed().notRequired(),
      }),
    evidence_not_required: Yup.boolean(),
    share_code_not_required: Yup.boolean(),
    share_code: Yup.string()
      .when('share_code_not_required', {
        is: false,
        then: Yup.string()
          .matches(shareCodeValidationWithoutSpaces, 'Please enter a valid share code')
          .required(
            'Please input your share code to progress. If you do not require a share code to work legally, please select the tickbox above to indicate this.',
          ),
        otherwise: Yup.string().notRequired(),
      }),
    visa_expiration_date_required: Yup.boolean()
      .when('evidence_not_required', {
        is: false,
        then: Yup.boolean().required('Please select if your visa has an expiration date'),
        otherwise: Yup.boolean().notRequired().nullable(),
      }),
    visa_expiration: Yup.date().typeError('Invalid date').min(new Date(), 'Visa cannot be expired.')
      .when(['evidence_not_required', 'visa_expiration_date_required'], {
        is: (evidence_not_required, visa_expiration_date_required) => evidence_not_required === false
        && visa_expiration_date_required === true,
        then: Yup.date().typeError('Invalid date').min(new Date(), 'Visa cannot be expired.').required('Please enter the expiration date of your visa'),
        otherwise: Yup.date().notRequired(),
      }),
  }),
  Yup.object({
    home_address_1: Yup.string().required('Please enter your address'),
    home_address_2: Yup.string(),
    home_city: Yup.string().required('Please enter your city'),
    home_county: Yup.string().required('Please enter your county'),
    home_post_code: Yup.string()
      .when('country', {
        is: 'United Kingdom',
        then: Yup.string().trim().ensure().min(1, 'Required'),
      }),
  }),
  Yup.object({
    term_address_1: Yup.string().required('Please enter your secondary address.  If you do not have a secondary address, please re-enter your primary address'),
    term_address_2: Yup.string(),
    term_city: Yup.string().required('Please enter your city'),
    term_county: Yup.string().required('Please enter your county'),
    term_post_code: Yup.string()
      .when('country', {
        is: 'United Kingdom',
        then: Yup.string().trim().ensure().min(1, 'Required'),
      }),
  }),
  Yup.object({
    tertiary_address_1: Yup.string(),
    tertiary_address_2: Yup.string(),
    tertiary_city: Yup.string()
      .when('tertiary_address_1', {
        is: value => value?.length > 0,
        then: Yup.string().required('Please enter your city'),
      }),
    tertiary_county: Yup.string()
      .when('tertiary_address_1', {
        is: value => value?.length > 0,
        then: Yup.string().required('Please enter your county'),
      }),
    tertiary_post_code: Yup.string()
      .when('tertiary_address_1', {
        is: value => value?.length > 0,
        then: Yup.string().required('Required'),
      }),
  }),
  Yup.object({
    transport_available: Yup.string().trim().ensure().min(1, 'Required'),
    emergency_name: Yup.string().required('Please provide full details for your emergency contact before progressing'),
    emergency_phone: Yup.string().required('Please provide full details for your emergency contact before progressing'),
    emergency_relationship: Yup.string().trim().ensure().min(1, 'Please provide full details for your emergency contact before progressing'),
    email_communication: Yup.boolean(),
    mobile_number: Yup.string()
      .required('Please enter your mobile number'),
    mobile_prefix: Yup.string()
      .required('Please select your prefix'),
    servelegal_community: Yup.boolean(),
  }),
  Yup.object({
    hear_about_us: Yup.string(),
    notes: Yup.string(),
    recommended_by: Yup.string(),
  }),
  Yup.object({
    about_yourself: Yup.string()
      .min(30, 'Please write a little bit more in order to progress your application')
      .required('Please enter a few words about yourself'),
    travel_outside: Yup.string(),
    why_servelegal: Yup.string()
      .min(30, 'Please write a little bit more in order to progress your application')
      .required('Please enter why you want to work for Serve Legal'),
  }),
  Yup.object().shape({
    communications_consent: Yup.boolean(),
    facial_images_consent: Yup.boolean(),
    privacy_notice: Yup.boolean()
      .oneOf([true], 'You have to accept the Privacy Notice')
      .required('You have to accept the Privacy Notice'),
    special_category_consent: Yup.boolean(),
  }),
];
