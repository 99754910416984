export const hasTermAddress = userInfo => {
  if (userInfo.country === 'United Kingdom') {
    return !!(userInfo.term_address_1 && userInfo.term_city && userInfo.term_post_code && userInfo.term_county);
  }
  return !!(userInfo.term_address_1 && userInfo.term_city && userInfo.term_county);
};

export const hasAuditorApplicationFields = userInfo =>
  !!(
    ((userInfo.preferred_pronouns !== 'Other' && userInfo.preferred_pronouns)
    || (userInfo.preferred_pronouns === 'Other' && userInfo.other_pronoun))
    && userInfo.preferred_first_name
    && userInfo.preferred_last_name
    && userInfo.emergency_phone
    && userInfo.emergency_name
    && userInfo.emergency_relationship
    && userInfo.transport_available
  );
