import { Cookie } from '../constants';
// import { cookieENV } from '../utils/getEnv';
import persistence from '.';

const cookieStorage = persistence('cookieStorage');

const removeAccess = () => {
  // TODO: const env = cookieENV();
  const env = 'servelegal';
  cookieStorage.removeItem(`${env}-${Cookie.SESSION_TOKEN}`);
  cookieStorage.removeItem(`${env}-${Cookie.USERNAME}`);
  cookieStorage.removeItem(`${env}-${Cookie.ROLES}`);
};

export { removeAccess };
