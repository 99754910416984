import { useFeatureFlag } from 'configcat-react';
import { Formik } from 'formik';
import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Button, Form, Grid, Icon } from 'semantic-ui-react';

import { entitiesAPI } from '../../../../../api';
import { AuditsConst, DEBOUNCE_MS, Entity } from '../../../../../constants';
import { enumOptionsSelector, taxonomyTermSelector } from '../../../../../state/constants/selectors';
import {
  FormFieldDateTimeWrapper,
  FormFieldSelectWrapper,
  FormFieldWrapper,
  TaxonomyTermOptions,
} from '../../../../common';
import {
  auditFilterDefaultValues,
  auditFilterValidationSchema,
} from '../../helpers';

const DATA_CY = 'audits-table-filter';
const tokensName = 'tokens';

const paidOptions = [
  {
    text: 'Paid', value: true,
  },
  {
    text: 'Not Paid', value: false,
  },
];

const scheduleTypeSelector = state => enumOptionsSelector(state, 'schedule_type');
const auditStatusSelector = state => enumOptionsSelector(state, 'audit_status');
const auditTokensTaxonomySelector = state => taxonomyTermSelector(state, AuditsConst.tokenVocabulary);
const itemToOrderTaxonomyTermSelector = state => taxonomyTermSelector(state, 'order_item');

const AuditsTableFilterAdminRole = ({ values: initialValues, onChange }) => {
  const { value: auditsFilterButton } = useFeatureFlag('auditsFilterButton', true);
  const scheduleTypeOptions = useSelector(scheduleTypeSelector);
  const auditTokens = useSelector(auditTokensTaxonomySelector);
  const auditStatusOptions = useSelector(auditStatusSelector);
  const itemToOrderTaxonomyTerm = useSelector(itemToOrderTaxonomyTermSelector);

  const patches = useQuery(
    [Entity.PATCHES],
    () => entitiesAPI.fetchAll(Entity.PATCHES),
  );
  const patchesSiteOptions = patches?.data?.items?.map(patch => ({ text: patch.name, value: patch.id })) || [];

  return (
    <>
      <Formik
        initialValues={{
          ...initialValues,
          order_schedule_type: initialValues.order_schedule_type ? [initialValues.order_schedule_type].flat() : [],
          status: initialValues.status ? [initialValues.status].flat() : [],
          site_patch_id: initialValues.site_patch_id ? [initialValues.site_patch_id].flat() : [],
          [tokensName]: initialValues[tokensName] ? [initialValues[tokensName]].flat() : [],
        }}
        validationSchema={auditFilterValidationSchema}
        onSubmit={values => {
          const valuesFiltered = Object.keys(values).reduce((acc, curr) => {
            if (curr !== 'undefined') {
              acc[curr] = values[curr];
            }
            return acc;
          }, {});
          const finalValues = _pickBy(valuesFiltered, value => value !== null && value !== undefined);
          if (finalValues.site_post_code && typeof finalValues.site_post_code === 'string') {
            finalValues.site_post_code = finalValues.site_post_code.split(',').map(code => code.trim());
          }
          onChange(finalValues);
        }}
      >
        {({ handleSubmit }) => {
          const debounceSubmit = _debounce(handleSubmit, DEBOUNCE_MS);
          const handleFieldChange = () => {
            debounceSubmit();
          };

          return (
            <Form inverted data-cy={DATA_CY}>
              <Grid padded columns={4}>
                <Grid.Row>
                  <Grid.Column>
                    <FormFieldDateTimeWrapper
                      label="Order Deadline - From"
                      name="order_end_date_from"
                      time={false}
                      {...(!auditsFilterButton && { onChange: handleSubmit })}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormFieldDateTimeWrapper
                      label="To"
                      name="order_end_date_to"
                      time={false}
                      {...(!auditsFilterButton && { onChange: handleSubmit })}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormFieldDateTimeWrapper
                      label="Audit Date - From"
                      name="date_of_visit_from"
                      time={false}
                      {...(!auditsFilterButton && { onChange: handleSubmit })}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormFieldDateTimeWrapper
                      label="To"
                      name="date_of_visit_to"
                      time={false}
                      {...(!auditsFilterButton && { onChange: handleSubmit })}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <FormFieldSelectWrapper
                      clearable
                      multiple
                      label="Schedule Type"
                      name="order_schedule_type"
                      options={scheduleTypeOptions}
                      {...(!auditsFilterButton && { onChange: handleSubmit })}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormFieldSelectWrapper
                      clearable
                      label="Paid"
                      name="paid"
                      options={paidOptions}
                      {...(!auditsFilterButton && { onChange: handleSubmit })}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormFieldSelectWrapper
                      clearable
                      multiple
                      label="Status"
                      name="status"
                      options={auditStatusOptions}
                      {...(!auditsFilterButton && { onChange: handleSubmit })}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormFieldWrapper
                      label="Post Code (split values with commas)"
                      name="site_post_code"
                      {...(!auditsFilterButton && { onChange: handleFieldChange })}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <FormFieldWrapper
                      label="Client"
                      name="client_name"
                      {...(!auditsFilterButton && { onChange: handleFieldChange })}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormFieldWrapper
                      label="Site"
                      name="site_name"
                      {...(!auditsFilterButton && { onChange: handleFieldChange })}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormFieldWrapper
                      label="Order"
                      name="order_internal_id"
                      {...(!auditsFilterButton && { onChange: handleFieldChange })}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormFieldWrapper
                      label="Auditor"
                      name="auditor_name"
                      {...(!auditsFilterButton && { onChange: handleFieldChange })}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <FormFieldSelectWrapper
                      clearable
                      multiple
                      label="Patches"
                      name="site_patch_id"
                      options={patchesSiteOptions}
                      {...(!auditsFilterButton && { onChange: handleSubmit })}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormFieldSelectWrapper
                      multiple
                      search
                      label="Tokens"
                      name={tokensName}
                      options={(auditTokens.terms || []).map((token, i) => ({
                        value: token.id,
                        text: token.token,
                        key: i,
                        nested: String(token.is_token),
                        disabled: !token.is_token,
                      }))}
                      {...(!auditsFilterButton && { onChange: handleSubmit })}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormFieldWrapper
                      label="Audit ID"
                      name="audit_internal_id"
                      {...(!auditsFilterButton && { onChange: handleFieldChange })}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormFieldSelectWrapper
                      clearable
                      multiple
                      label="Item to order"
                      name="item_to_order"
                      options={TaxonomyTermOptions(itemToOrderTaxonomyTerm)
                        .sort((a, b) => a.text.localeCompare(b.text))}
                      {...(!auditsFilterButton && { onChange: handleSubmit })}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <FormFieldDateTimeWrapper
                      label="Order Start Date"
                      name="order_start_date_from"
                      time={false}
                      {...(!auditsFilterButton && { onChange: handleSubmit })}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormFieldWrapper
                      label="Auditor ID"
                      name="auditor_internal_id"
                    />
                  </Grid.Column>
                </Grid.Row>
                {auditsFilterButton && (
                  <Grid.Row>
                    <Grid.Column></Grid.Column>
                    <Grid.Column></Grid.Column>
                    <Grid.Column></Grid.Column>
                    <Grid.Column textAlign={'right'}>
                      <Button
                        icon
                        labelPosition={'right'}
                        onClick={handleSubmit}
                      >
                        Apply Filters
                        <Icon name={'search'}/>
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                )}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

AuditsTableFilterAdminRole.defaultProps = {
  values: auditFilterDefaultValues,
};

AuditsTableFilterAdminRole.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    auditor_internal_id: PropTypes.string,
    auditor_name: PropTypes.string,
    client_name: PropTypes.string,
    order_deadline_from: PropTypes.string,
    order_deadline_to: PropTypes.string,
    order_internal_id: PropTypes.string,
    order_schedule_type: PropTypes.array,
    site_name: PropTypes.string,
    site_patch_id: PropTypes.array,
    site_post_code: PropTypes.string,
    status: PropTypes.array,
  }),
};

export default AuditsTableFilterAdminRole;
