import React from 'react';
import { Form } from 'semantic-ui-react';

import idImage from '../../../../../assets/images/documents/ID.jpg';
import { FormFieldImageUploadWrapper } from '../../../../common';
import FormLayout from '../../RegisterAuditorFormLayout/RegisterAuditorFormLayout.component';
import styles from './RegisterAuditorFormStepSeven.module.scss';

const RegisterForm = () => (
  <FormLayout subtitle="2 of 3" title="Upload images">
    <span className={styles.titleQuestion}>
      {'A photo of your ID'}
    </span>
    <img alt="bsi-logo" className={styles.image} src={idImage} />
    <p className={styles.noteQuestionItalic}>
      {'A scanned copy of the form of ID you usually carry. For example, a UK Driving Licence, UK Passport or a form of ID which is stamped with the PASS hologram.'}
    </p>
    <p className={styles.noteQuestion}>
      {'Please ensure this image is clear and displays all information as shown in the example above.'}
    </p>
    <Form.Field width={16}>
      <FormFieldImageUploadWrapper
        required
        thumbnail
        accept={['image/jpg', 'image/png']}
        name="identification"
      />
    </Form.Field>
    <span className={styles.noteQuestion}>
      {'Allowed file types: .jpg, .png'}
    </span>
  </FormLayout>
);

export default RegisterForm;
