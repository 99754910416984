/* eslint-disable no-console */
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Dimmer, Form, Loader, Tab } from 'semantic-ui-react';

import { multipartAPI } from '../../../../api';
import { AccountConst, Entity, UserRole } from '../../../../constants';
import { useUserRole } from '../../../../hooks';
import { fetchUserInfo } from '../../../../state/constants/actionCreators';
import { auditorBankInfoValidation } from '../../../../utils/accountInfo';
import GenericModal from '../../../common/GernericModal/GenericModal.component';
import { auditorEditValidationSchema } from '../helpers';
import styles from './AuditorDetailsEdit.module.scss';
import EditAnswersTab from './EditAnswersTab/EditAnswersTab.component';
import EditAuditorInfoTab from './EditAuditorInfoTab/EditAuditorInfoTab.component';
import EditAuditorProfileTab from './EditAuditorProfileTab/EditAuditorProfileTab.component';
import EditBankInfoTab from './EditBankInfoTab/EditBankInfoTab.component';

const DATA_CY = 'auditor-edit';

const tabsComponents = {
  editAnswersTab: EditAnswersTab,
  editAuditorInfoTab: EditAuditorInfoTab,
  editAuditorProfileTab: EditAuditorProfileTab,
  editBankInfoTab: EditBankInfoTab,
};

const parsePanes = (role, mode) => Object.values(AccountConst.AuditorAccountTabs).map(tab => {
  const TagName = tabsComponents[tab.component];
  return {
    menuItem: tab.name,
    render: () => (<TagName mode={mode} role={role}/>),
  };
});

const panes = (role, mode) => parsePanes(role, mode);

const isEmptyObject = obj => Object.keys(obj).length === 0;

const errorString = errors => Object.entries(errors)
  // eslint-disable-next-line no-unused-vars
  .map(([_field, message]) => `${message}`)
  .join('\n');

const AuditorDetailsEdit = ({ data, submitFormRef, refetch }) => {
  const role = useUserRole();
  const dispatch = useDispatch();
  const { mode, tabId, entityId } = useParams();
  const alert = useAlert();
  const { pathname } = useLocation();
  const entityPath = pathname.split('/')[1];
  const [validationMsg, setValidationMsg] = useState(null);
  const [tabIndex, setTabIndex] = useState(tabId || 0);

  const { mutate: auditorMutate, status: userMutateStatus } = useMutation(
    multipartAPI.update,
    {
      onSuccess: () => {
        alert.success('Auditor updated');
        dispatch(fetchUserInfo({ role }));
        refetch();
      },
      onError: error => {
        alert.error(`Error updating auditor: ${error.message}`);
      },
    },
  );

  const onCloseModal = () => {
    setValidationMsg(null);
    setTabIndex(2);
  };

  const bankInfoValidation = values => {
    const bankValidationMessage = auditorBankInfoValidation({ values });
    setValidationMsg(bankValidationMessage);
  };

  const onAuditorSubmit = values => {
    const formData = new FormData();
    const { identification, image, passport, visa, ...filteredValues } = values;

    Object.entries(filteredValues).forEach(([key, value]) => formData.append(key, value));

    if (entityPath === 'account') {
      return auditorMutate({
        entity: Entity.AUDITORS,
        entityId: 'me',
        payload: formData,
        version: 'v2',
      });
    }

    return auditorMutate({
      entity: Entity.AUDITORS,
      entityId,
      payload: formData,
    });
  };

  return (
    <>
      <GenericModal
        buttons={[{ label: 'Accept', func: onCloseModal }]}
        open={validationMsg !== null}
        onDismiss={onCloseModal}
      >
        <h4>
          {validationMsg}
        </h4>
      </GenericModal>
      {userMutateStatus === 'loading' ? (
        <>
          <Dimmer active inverted>
            <Loader size="large">
              {'Uploading auditor'}
            </Loader>
          </Dimmer>
        </>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={data}
          validationSchema={auditorEditValidationSchema(role)}
          onSubmit={onAuditorSubmit}
        >
          {({ handleSubmit, values, errors }) => {
            const presSubmit = () => {
              if (!isEmptyObject(errors)) {
                alert.error(<pre>
                  {errorString(errors)}
                </pre>);
              }
              if (role === UserRole.AUDITOR) {
                bankInfoValidation(values);
              }
              handleSubmit();
            };
            return (
              <Form>
                <button ref={submitFormRef} hidden type="submit" onClick={presSubmit} />
                <Tab
                  activeIndex={tabIndex}
                  data-cy={`${DATA_CY}-tabs`}
                  menu={{ secondary: true, pointing: true, className: styles.tab }}
                  panes={panes(role, entityPath === 'account' ? 'edit' : mode)}
                  values={values}
                  onTabChange={(ev, { activeIndex }) => setTabIndex(activeIndex)}
                />
              </Form>
            );
          }
          }
        </Formik>
      )}
    </>
  );
};

AuditorDetailsEdit.propTypes = {
  data: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  submitFormRef: PropTypes.object.isRequired,
};

export default AuditorDetailsEdit;
